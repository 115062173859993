export const actionTypeConfig = {
  clientAnnouncement: { label: '佈告資訊頁', value: 'clientAnnouncement' },
  memberGame: { label: '抽獎活動', value: 'memberGame' },
  link: { label: '自定義連結', value: 'link' },
  none: { label: '無', value: 'none' },
}

export const layoutConfig = {
  image: { label: '純圖片', value: 'image' },
  text: { label: '純文字', value: 'text' },
  textButton: { label: '文字 + 按鈕', value: 'textButton' },
}

export const dataSourcesConfig = {
  APPOINTMENT_ORDER: { label: '預約紀錄', value: 'APPOINTMENT_ORDER', featureKeys: ['admin.appointmentOrder.page'] },
  EC_ORDER: { label: '電商訂單紀錄', value: 'EC_ORDER', featureKeys: ['admin.ecommerceOrder.page'] },
  SALE_RECORD: { label: '銷售紀錄', value: 'SALE_RECORD', featureKeys: ['admin.salesRecord.page'] },
  MEMBER_STORE_ORDER: { label: '商城訂單', value: 'MEMBER_STORE_ORDER', featureKeys: ['admin.memberStoreOrder.page'] },
  MEMBER_EXTERNAL_TRANSACTION: { label: '第三方消費紀錄', value: 'MEMBER_EXTERNAL_TRANSACTION', featureKeys: ['admin.memberExternalTransaction.page'] },
  // MEMBER_REFERRAL_RECORD: { label: '會員推薦紀錄', value: 'MEMBER_REFERRAL_RECORD', featureKeys: [] },
}

export const dataSourceKeysConfig = {
  amount: { label: '訂單金額', value: 'amount' },
  itemLength: { label: '商品件數', value: 'itemLength' },
}
