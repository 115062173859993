import { admin2 } from '@/api/instance'

export const GetMemberCenterAd = async ({
  shopId,
  title,
  actionType,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberCenterAd`,
    params: {
      title,
      actionType,
      start,
      limit,
    },
  })
}

export const GetMemberCenterAdCount = async ({
  shopId,
  title,
  actionType,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberCenterAd/count`,
    params: {
      title,
      actionType,
    },
  })
}

export const CreateMemberCenterAd = async ({
  shopId,
  title,
  order,
  publishedStartAt,
  publishedEndAt,
  type,
  textBackgroundColor,
  buttonText,
  actionType,
  actionLink,
  ImageId,
  ActionMemberGameId,
  ActionClientAnnouncementId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberCenterAd`,
    data: {
      title,
      order,
      publishedStartAt,
      publishedEndAt,
      type,
      textBackgroundColor,
      buttonText,
      actionType,
      actionLink,
      ImageId,
      ActionMemberGameId,
      ActionClientAnnouncementId,
    },
  })
}

export const UpdateMemberCenterAd = async ({
  shopId,
  id,
  title,
  order,
  publishedStartAt,
  publishedEndAt,
  type,
  textBackgroundColor,
  buttonText,
  actionType,
  actionLink,
  ImageId,
  ActionMemberGameId,
  ActionClientAnnouncementId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberCenterAd/${id}`,
    data: {
      title,
      order,
      publishedStartAt,
      publishedEndAt,
      type,
      textBackgroundColor,
      buttonText,
      actionType,
      actionLink,
      ImageId,
      ActionMemberGameId,
      ActionClientAnnouncementId,
    },
  })
}

export const FindMemberCenterAd = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberCenterAd/${id}`,
  })
}

export const DeleteMemberCenterAd = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/memberCenterAd/${id}`,
  })
}

export const GetClientAnnouncement = async ({
  shopId,
  title,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientAnnouncement`,
    params: {
      title,
      start,
      limit,
    },
  })
}
export const FindClientAnnouncement = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientAnnouncement/${id}`,
  })
}

export const GetClientAnnouncementCount = async ({
  shopId,
  title,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientAnnouncement/count`,
    params: {
      title,
    },
  })
}

export const DeleteClientAnnouncement = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/clientAnnouncement/${id}`,
  })
}

export const CreateClientAnnouncement = async ({
  shopId,
  title,
  publishedStartAt,
  publishedEndAt,
  activityStartAt,
  activityEndAt,
  enabledProgressBar,
  progressBarSettings,
  contentBlocks,
  ImageId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/clientAnnouncement`,
    data: {
      title,
      publishedStartAt,
      publishedEndAt,
      activityStartAt,
      activityEndAt,
      enabledProgressBar,
      progressBarSettings,
      contentBlocks,
      ImageId,
    },
  })
}
export const UpdateClientAnnouncement = async ({
  shopId,
  id,
  title,
  publishedStartAt,
  publishedEndAt,
  activityStartAt,
  activityEndAt,
  enabledProgressBar,
  progressBarSettings,
  contentBlocks,
  ImageId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/clientAnnouncement/${id}`,
    data: {
      title,
      publishedStartAt,
      publishedEndAt,
      activityStartAt,
      activityEndAt,
      enabledProgressBar,
      progressBarSettings,
      contentBlocks,
      ImageId,
    },
  })
}

export const CheckBindMemberCenterAd = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientAnnouncement/${id}/checkBindMemberCenterAd`,
  })
}

export const GetClientLink = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientAnnouncement/${id}/clientLink`,
  })
}
